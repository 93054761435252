import Vue from "vue";
import Vuex, { Store } from "vuex";

//API
import api from "@/providers/api";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: "citizen-admin",
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  getters: {
    getDepartment(state) {
      return state.workerDepartment;
    },
    getRole(state) {
      return state.role;
    },
    getUser(state) {
      return state.user;
    },
    isUser(state) {
      return !!state.user;
    },
    getError(state) {
      return state.error;
    },
    getDatasets(state) {
      return state.datasets;
    },
  },
  state: {
    user: null,
    userData: null,
    role: "anonymous",
    error: false,
    workerDepartment: null,
    datasets: {},
  },
  mutations: {
    SET_ROLE(state, role) {
      state.role = role;
    },
    SET_DEPARTMENT(state, department) {
      state.workerDepartment = department;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setUserData(state, payload) {
      state.userData = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setDatasets(state, payload) {
      state.datasets = payload;
    },
  },
  actions: {
    setRole(context, payload) {
      context.commit("SET_ROLE", payload);
    },
    setWorkerDepartment(context, payload) {
      context.commit("SET_DEPARTMENT", payload);
    },
  },
  strict: process.env.DEV,
  plugins: [vuexLocal.plugin],
});
