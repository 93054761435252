import Vue from "vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";

Vue.component(FeatherIcon.name, FeatherIcon);

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
const options = {
  timeout: 1500,
  draggable: false,
  closeOnClick: true,
};

Vue.use(Toast, options);

const moment = require("moment");
require("moment/locale/uk");

Vue.use(require("vue-moment"), {
  moment,
});
