import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import db from "@/providers/db";
import store from "../store";

function authService(app) {
  const auth = getAuth(app);
  return {
    logOut: async () => {
      var result = await signOut(auth)
        .then(() => {
          result = true;
          store.commit("setUser", null);
          store.commit("SET_ROLE", "anonymous");
        })
        .catch((error) => {
          console.log(error);
          result = false;
        });
      return result;
    },
    createUserWithEmail: async (email, password) => {
      var result = await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          store.commit("setUser", user);
          return {
            success: true,
            user: user,
          };
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;

          return {
            error: true,
            code: errorCode,
            message: errorMessage,
          };
        });
      return result;
    },
    checkIfAdmin: async () => {
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          // Confirm the user is an Admin.
          if (!!idTokenResult.claims.admin) {
            // Show admin UI.
            showAdminUI();
          } else {
            // Show regular user UI.
            showRegularUI();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    signInWithEmail: async (email, password) => {
      var result = await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;

          return {
            success: true,
            user: user,
          };
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          return {
            error: true,
            code: errorCode,
            message: errorMessage,
          };
        });

      return result;
    },
    onUserAuthChange: async () => {
      var result = await onAuthStateChanged(auth, async (user) => {
        if (user) {
          store.commit("setUser", user);
          const uid = user.uid;

          var claims = JSON.parse(user.reloadUserInfo.customAttributes);
          if (claims.worker) {
            store.commit("SET_ROLE", "worker");
          }
          if (claims.admin) {
            store.commit("SET_ROLE", "admin");
          }
          return {
            is_signed: true,
            uid: uid,
          };
        } else {
          store.commit("SET_ROLE", "anonymous");
          return {
            is_signed: false,
          };
        }
      });
      return result;
    },
  };
}

export { authService as default };
