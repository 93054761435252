// import { async } from "@firebase/util";

import { getFirestore } from "firebase/firestore";
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  query,
  where,
  doc,
  updateDoc,
  deleteDoc,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";

function firestoreMethods(app) {
  const db = getFirestore(app);
  return {
    add: async (collectionName, dataObject) => {
      var result;
      try {
        const docRef = await addDoc(collection(db, collectionName), dataObject);
        console.log("Document written with ID: ", docRef.id);
        result = {
          success: true,
          doc_id: docRef.id,
        };
      } catch (e) {
        console.error("Error adding document: ", e);
        result = {
          error: true,
          message: e,
        };
      }
      return result;
    },
    delete: async (collection, uid, newData) => {
      let result = await deleteDoc(doc(db, collection, uid));
      return result;
    },
    update: async (collection, uid, newData) => {
      const docRef = doc(db, collection, uid);

      // Set the "capital" field of the city 'DC'
      let result = await updateDoc(docRef, newData);
      return result;
    },
    getCollection: async (inputArgs) => {
      var result = {};
      result["docs"] = [];
      let collectionRef = collection(db, inputArgs.collectionName);
      let args = [collectionRef];
      if (inputArgs.where) {
        args.push(
          where(inputArgs.where[0], inputArgs.where[1], inputArgs.where[2])
        );
      }
      if (inputArgs.where2) {
        args.push(
          where(inputArgs.where2[0], inputArgs.where2[1], inputArgs.where2[2])
        );
      }
      if (inputArgs.order) {
        args.push(orderBy(inputArgs.order[0], inputArgs.order[1]));
      }
      if (inputArgs.limitBy) {
        args.push(limit(inputArgs.limitBy));
      }
      if (inputArgs.startAfter) {
        args.push(startAfter(inputArgs.startAfter));
      }
      let q = query(...args);
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        var item = doc.data();
        item["uid"] = doc.id;
        result.docs.push(item);
      });
      result["lastVisible"] = querySnapshot.docs[querySnapshot.docs.length - 1];
      return result;
    },

    getCollectionWhere: async (collectionName, conditions) => {
      const collRef = collection(db, collectionName);

      // Create a query against the collection.
      const q = query(
        collRef,
        where(conditions[0], conditions[1], conditions[2])
      );
      const querySnapshot = await getDocs(q);
      var result = [];
      querySnapshot.forEach((doc) => {
        var item = doc.data();
        item["uid"] = doc.id;
        result.push(item);
      });
      return result;
    },

    getDocument: async (collectionName, uid) => {
      console.log(uid);
      const docRef = doc(db, collectionName, uid);
      const docSnap = await getDoc(docRef);
      var result;
      if (docSnap.exists()) {
        result = docSnap.data();
      } else {
        result = false;
      }
      return result;
    },
  };
}

export { firestoreMethods as default };
